import fullsizeImagesUI from './fullsize';
import slidersUI from './sliders';
import mapsUI from './maps-ui';

;(function(window, document, $) {
	$('a[href^="tel"]').each(function() {
		const linkHref = $(this).attr('href');

		$(this).attr('href', linkHref.replace(/ /igm, ''));
	});

	fullsizeImagesUI();

	slidersUI();

	mapsUI();
})(window, document, window.jQuery);
